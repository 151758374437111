
@font-face {
  font-family: 'SafiarSignature';
  src: url('fonts/SafiarSignature.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSansProRegular';
  src: url('fonts/SourceSansPro-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow-Regular';
  src: url('fonts/Barlow-Regular.otf');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Barlow-Medium';
  src: url('fonts/Barlow-Medium.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow-Bold';
  src: url('fonts/Barlow-Bold.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow-SemiBold';
  src: url('fonts/Barlow-SemiBold.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Amaranth-Regular';
  src: url('fonts/Amaranth-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HammersmithOne-Regular';
  src: url('fonts/HammersmithOne-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSansProLight';
  src: url('fonts/SourceSansPro-Light.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoRegular';
  src: url('fonts/Roboto-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoLight';
  src: url('fonts/Roboto-Light.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamLight';
  src: url('fonts/GothamLight.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamMedium';
  src: url('fonts/GothamMedium.ttf');
  font-weight: normal;
  font-style: normal;
}
