.tabs-orange {
  //background-color: #fdc16e!important;
  background-color: rgba(0, 0, 0, .03);
}
.nav-tabs.tabs-orange li a.active {
  background-color: #ffffff!important;
  //background-color: #ffffff!important;

}

.nav-tabs {
  -webkit-border-radius: .3rem .3rem 0 0;
  border-radius: .3rem .3rem 0 0;
  border: none;
}

.nav-tabs.tabs-orange li a.active {
  border: none;
  //border-bottom: 3px solid #ffeb3b;
  color: #565656; //#fff;
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  //border-color: #ffeb3b #ffeb3b #fff;
}

.nav-tabs.tabs-orange li a.active:hover {
  color: #565656 !important;
}

.nav-tabs li a:hover {
  border: none;
  color: #ffffff
}

.tab-content {
  padding: 1rem;
  padding-top: 2rem;
  border: none;
  /*border-left: 1px solid #dee2e6!important;
  border-right: 1px solid #dee2e6!important;
  border-bottom: 1px solid #dee2e6!important;*/
}

.tab-content {
  font-weight: 400;
  background-color: #ffffff;
}


.nav-tabs.tabs-orange li a {
  font-family: "RobotoRegular"
}

