nav.navbar {
  //background-image: linear-gradient(-171deg, $bookitup-light-orange, $bookitup-deep-orange);
  //background-image: radial-gradient(circle, #fbc682, #f07373) !important;
  background-image: linear-gradient(90deg ,#e8b470,#f07373)!important;
  //background-image: radial-gradient(circle,#f07373,#f07373)!important;
  //background-color: #fc6262 !important;
  font-size: 1rem;
}

.navbar {
  padding: 5px 1.5rem;
}

nav.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #ffffff;
}

nav.navbar-light .navbar-toggler {
  color: #ffffff;
  border-color: #ffffff;
}

.pane.head .dropdown-menu.show {
  //transform: translate3d(-221px, 30px, 0px) !important;
  width: 200px;
}

.dropdown-item {
  overflow: hidden;
  text-overflow: ellipsis;
  height: 40px;
  padding: 0px;
  padding-left: 5px;
  border-radius: 4px;
  font-size: 0.875rem;
}

// Hover bei UserName
nav .dropdown > a.nav-link:hover {
  color: #ffffff !important;
}

.navbar-nav li.active {
  font-family: "RobotoRegular";
  font-weight: 800;
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar li {
  font-family: "RobotoRegular";
  list-style: none;
}

.navbar li a {
  color: #ffffff;
}

/*.navbar-toggler {
  background: linear-gradient(40deg,#ffd86f,#fc6262) !important;
  border: none;
}*/

.navbar-toggler {
  border: none;
}

.navbar-toggler span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
  margin-top: 3px;
}

.navbar-toggler a {
  color: #fff;
  font-size: 1rem;
}

.navbar-toggler.active a {
  background-color: rgba(255, 255, 255, .1);
}

.animated-icon {
  width: 22px;
  height: 20px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.navbar-toggler span {
  background: #ffffff;
}

.animated-icon.open span:nth-child(1),
.animated-icon.open span:nth-child(4){

  top: 11px;
  width: 0%;
  left: 50%;
}
.animated-icon.open span:nth-child(2) {

  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.animated-icon.open span:nth-child(3) {

  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.animated-icon span:nth-child(1) {
  top: 3px;
}

.navbar-toggler span:nth-child(2), .navbar-toggler span:nth-child(3) {
  top: 10px;
}

.navbar-toggler span:nth-child(4) {
  top: 17px;
}


// Navbar Logo

.navbar-brand img,
.logo img {
  width: 23px;
}

@media (min-width: 768px) {
  nav>.navbar-brand {
    display: none;
  }

  /*.nav-item span {
    display: none;
  }*/

  .navbar .show .mobile-search.closed {
    display: none;
  }

  .fixed-bottom .table .form-group {
    width: 165px;
  }
}


@media (max-width: 980px) {
  .navbar-collapse .logo {
    display: none;
  }

}


.nav-item a {
  padding: 10px !important;
  padding-left: 15px !important;
}

.navbar .collapsing .logo {
  display: none;
}

.nav li.nav-item a,
.nav li.nav-item a:hover {
  display: block;
  padding: 20px 24px !important;
  font-size: 13px;
  // text-transform: uppercase;
  //color: #000000;
  //color: rgba(255,255,255,.7);
  text-align: center;
  -webkit-border-radius: 0;
  border-radius: 0;
  border: none;
  cursor: pointer;
}
