/*.css-xo7z33-option {
  background-color: #ffecde !important;
  color: #000 !important;
}
.css-dpec0i-option {
  background-color: #ffecde !important;
}

.css-bg1rzq-control.select__control,
.css-bg1rzq-control,
.css-1szy77t-control {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  box-shadow: none !important;
  border-color: #7d7d7d !important;
}

.select__control--is-focused,
.select__control--menu-is-open{
  box-shadow: none !important;
  border-color: #7d7d7d !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 20px !important;
}

/* aufgeklapptes Menü liegt über Label */
/*.css-kj6f9i-menu {
  z-index: 2 !important;
}*/

.css-bg1rzq-control,
.css-1szy77t-control {
  min-height: 35px !important;
  border-color: rgba(0, 0, 0, 0.125) !important;
}

.css-1wy0on6 {
  height: 33px;
}

.css-1hwfws3 {
  padding-bottom: 1px !important;
}

.css-1pcexqc-container.big {
  width: 350px;
}

.Select-menu-outer {
  border: none;
  z-index: 2;
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  padding-left: 2px;
  padding-right: 2px;
}

// document create

.big .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.big .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  font-size: 2rem;
}

.documenttype .form-control,
.documenttype .Select-control,
.documenttype .Select.is-focused > .Select-control,
.documenttype .Select.is-focused:not(.is-open) > .Select-control {
  border: none;
}


.css-dvua67-singleValue.select__single-value {
  width: 100%;
}

.noborder .css-bg1rzq-control.select__control,
.noborder .css-bg1rzq-control,
.noborder .css-bg1rzq-control:hover,
.noborder .css-1szy77t-control,
.noborder .css-1szy77t-control.select__control--is-focused{
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  background-color: transparent !important;
  border-radius: 0px !important;
  //border-bottom: 1px solid rgba(0, 0, 0, .125) !important;
  border-bottom: none !important;
}

.css-bg1rzq-control.select__control .form-control,
.css-bg1rzq-control .form-control,
.css-bg1rzq-control:hover .form-control,
.css-1szy77t-control .form-control,
.css-1szy77t-control.select__control--is-focused .form-control {
  padding: 0.375rem 0.75rem;
  font-weight: 100;
  //color: #9ca6af;
}

.big .css-bg1rzq-control,
.big .css-1szy77t-control,
.form-control .big {
  font-size: 2rem;
}

.css-dvua67-singleValue .form-control.big {
  font-size: 2rem;
}

.big .form-group {
  margin-top: 0px !important;
}

.big .form-control {
  margin-top: 22px !important;
}
.big .DayPickerInput .form-control {
  margin-top: 0px !important;
}

.css-kj6f9i-menu {
  z-index: 2 !important;
}

.css-1hwfws3.select__value-container.select__value-container--has-value {
  padding-left: 0px;
}


.select__control--is-focused,
.css-1szy77t-control {
  //border: none !important;
  border: 1px solid hsl(0,0%,80%) !important;
  box-shadow: none !important;
}

.css-xo7z33-option {
  //background-color: #2684FF;
  background-color: $primary-takeoff !important;
  color: hsl(0,0%,100%);
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tab-highlight-color: rgba(0,0,0,0);
  box-sizing: border-box;
}

.css-fk865s-option {
  background-color: transparent;;
  color: inherit;
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tab-highlight-color: rgba(0,0,0,0);
  box-sizing: border-box;
}

.css-dpec0i-option {
  //background-color: #DEEBFF;
  background-color: $primary !important;
  color: #ffffff !important;
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tab-highlight-color: rgba(0,0,0,0);
  box-sizing: border-box;
}

