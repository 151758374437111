.link {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  background-color: rgba(0, 0, 0, .03);
}

.clickable-icon {
  color: #9ca6af;
}

.clickable-icon:hover {
  color: #495057;
  cursor: pointer;
}