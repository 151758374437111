
.pane {
  //border-radius: 4px 4px 0 0;
  background-color: #fff;
  //box-shadow: 0 1px 3px 0 rgba(21,27,38,.15);
  //margin-bottom: 10px;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 2px;
  margin-bottom: 5px;
  margin: 5px;
}

.pane .head,
.pane .footer {
  //border-bottom: 1px solid #e0e6e8;
  //padding-left: 35px;
  //padding-right: 15px;
  //margin-top: 0px;
  //margin-bottom: 4px;
  //box-shadow: none;
  background-color: #f6f8f9;
  //padding-bottom: 4px;
  //padding-top: 4px;
  border: none;
  padding-left: 10px;
  border-radius: 0px 0px 0px 0px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-family: "RobotoRegular";
  border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.pane .head svg {
  margin-right: 5px;
  //color: #969696;
  margin-left: 5px;
}

.pane.bottom {
  border-top: 1px solid #e0e6e8;
  height: 60px;
  margin: 0 -30px;
  padding-left: 35px;
  padding-right: 15px;
  box-shadow: none;
  border-radius: 0px 0px 0 0;
}

.pane.bottom .form-group > input,
nav .form-group > input {
  margin-top: 0rem;
}


.pane .head h6 {
  margin-top: 9px;
  font-family: "RobotoLight", -apple-system;
  display: inline-block;
  font-size: 0.85rem;
}

.pane .head {
  //color: #969696;
  color: #666666;
  font-size: 0.9rem;
}

.pane .table th, .table td {
  padding: 0.35rem;
}


.pane .head .Select-control {
  margin-top: 0px;
}

.pane .head .form-group {
  margin-bottom: -14px;
  margin-top: 0px;
}