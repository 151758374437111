css-1bdtt6f,
.css-p6rgdl {
  z-index: 1051;
}

.css-p6rgdl,
.css-okqsy5 {
  margin-bottom: 70px;
}

.css-1ntg56o {
  background-color: $info !important;
}

.css-121eudh {
  background-color: #ffd86f !important;
}
.css-otip-ToastElement {
  color: #c79433 !important;
}


/*.css-1y24ddd {
  background-color: #6BA581 !important;
}

.css-7yrw28-ToastElement {
  background-color: #a1e2a6 !important;
  color: #087732 !important;
}*/
