.actions-left-hover-item {
   padding-left: 30px;
 }

.actions-left-hover-item:hover {
  padding-left: unset;
}

.actions-left-hover-item .actions-for-item {
  display: none;
}

.actions-left-hover-item:hover .actions-for-item {
  display: flex;
  padding-left: unset;
}

.actions-right-hover-item {
  padding-right: 30px;
}

.actions-right-hover-item:hover {
  padding-right: unset;
}

.actions-right-hover-item .actions-for-item {
  display: none;
}

.actions-right-hover-item:hover .actions-for-item {
  display: flex;
  padding-right: unset;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  color: #666666;
  font-size: 12px;
}

.padding-5 {
  padding: 5px;
}

.box {
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 4px;
  padding: 5px;
  background-color: #ffffff;
}

.rounded {
  border-radius: 4px;
}

.paper {
  position: relative;
  margin: 1px 16px;
  background: #fff;
  border-radius: 2px;
}

@media (max-width: 576px) {
  .paper {
    margin: 0px;
  }
}

.paper::before,
.paper::after {
  content: '';
  position: absolute;
  bottom: 10px;
  width: 40%;
  height: 10px;
  box-shadow: 0 5px 14px rgba(0,0,0,.7);
  z-index: -1;
  transition: all .3s ease-in-out;
}

.paper::before {
  left: 15px;
  transform: skew(-5deg) rotate(-2deg);
}

.paper::after {
  right: 15px;
  transform: skew(5deg) rotate(2deg);
}

/*.paper:hover::before,
.paper:hover::after {
  box-shadow: 0 2px 14px rgba(0,0,0,.4);
}

.paper:hover::before {
  left: 5px;
}

.paper:hover::after {
  right: 5px;
}*/

hr {
  margin-top: 0.5rem;
}