//$bookitup-light-orange: #FFBE00 !default;
$bookitup-light-orange: #ffd86f !default;

//$bookitup-deep-orange: #ff6600 !default;
$bookitup-deep-orange: #fc6262 !default;
$bookitup-deep-black: #282828 !default;

$bookitup-new-orange: #FC4D4A !default;
$bookitup-new-light-orange: #FF514F !default;

//$primary: $bookitup-light-orange !default;
//$primary: #7a87ca !default;
$primary: #1D5191 !default;
$primary-takeoff: #1A4983 !default;
$primary-hover: #ffffff !default;
// gold c9af87
$secondary: #f07373 !default;
$secondary-takeoff: #ec6767 !default;
$secondary-hover: #ffffff !default;

//$secondary: #c1bdb5 !default;
$default: #c1bdb5 !default;
//$secondary: rgba(0, 0, 0, .03)  !default;
//$success: $bookitup-deep-orange !default;
$warning: $bookitup-light-orange !default;

$info: $primary;
$warning: $bookitup-light-orange;
$success: #a1e2a6;
$link-color: #7d7d7d;

.link-style-danger {
  color: #ff3b30 !important;
  font-weight: 700;
  font-family: "Circular" !important;
}

.link-style-danger:hover {
  color: #cc2f26 !important;
  text-decoration: none !important;
}

.link-style-blue {
  color: #1b72b7 !important;
  font-weight: 700;
  font-family: "Circular" !important;
}

.link-style-blue:hover {
  color: #395d80 !important;
  text-decoration: none !important;
}


::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.navigation-list {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  overflow: -moz-scrollbars-none;
}
.navigation-list::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
  overflow: -moz-scrollbars-none;
}

// show PDFs bigger in FileModalDialog
.react-pdf__Page__canvas {
  max-width: 650px;
}

.react-pdf__Page__textContent {
  display: none;
}

@media (min-width: 992px) {
  .animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform:translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transition: transform .5s;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@import '../node_modules/react-tippy/dist/tippy.css';
@import 'fonts.scss';
@import '../node_modules/bootstrap/scss/bootstrap';
@import "Atoms/bootstrap-component-adjustments.scss";
@import "Atoms/toast-component-adjustments.scss";
@import "Atoms/Dropdowns.scss";
@import "Atoms/Buttons.scss";
@import "Molecules/Dialogs.scss";
@import 'Templates/Navigation/Navigation';
@import 'Molecules/Searchbar.scss';
@import 'Organismns/Navigation/ListGroupItem';
@import 'Atoms/bootstrap-breadcrumb.scss';
@import 'Atoms/Switch.scss';
@import 'Atoms/Selectfield.scss';
@import 'Molecules/Placeholder.scss';
@import 'Molecules/DynamicFieldWrapper.scss';
@import 'Atoms/InputFields.scss';
@import 'Atoms/InputFields-DayPicker.scss';
@import 'Molecules/ActionBar.scss';
@import 'Atoms/Table.scss';
@import 'Molecules/Pane.scss';
@import 'Atoms/Tabs.scss';
@import 'Atoms/InputFields-Validation.scss';
@import 'Atoms/InputFields-Radio.scss';
@import 'Atoms/InputFields-Checkbox';
@import 'Atoms/InputFields-Text';
@import 'Atoms/modal.scss';
@import 'Atoms/common.scss';
@import "Atoms/buttons/Clickable.scss";
@import "Atoms/Formatter/SimpleLabelWrapper";
@import "Atoms/inputs/SimpleEditableText.scss";
@import "Atoms/DayPicker.scss";
@import "../node_modules/react-datepicker/dist/react-datepicker.css";
@import "Atoms/inputs/EditableDateInput";
@import "Utils/hover-min.css";
@import '../node_modules/rc-slider/assets/index.css';
@import 'Atoms/inputs/RadioBox.scss';
@import 'Atoms/ToastManager.scss';

textarea, select, input, button, div {
  outline: none;
}

body {
  font-size: 0.875rem;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  //background-color: #ffffff;
  background-color: rgb(251, 251, 251);
  //padding-bottom: 70px;
}

.container {
  padding: 0;
}

@media (max-width: 768px) {
  .container{
    max-width: none;
  }
}

fieldset {
  min-width: 0;
  padding: 0;
  padding-left: 0px;
  margin: 0;
  border: 0;
}

.custom-control-label {
  margin-bottom: 14px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
  padding-right: 1rem;
}

.form-header {
  color: #fff;
  text-align: center;
  margin-top: -3.13rem;
  margin-bottom: 3rem;
  -webkit-border-radius: .125rem;
  border-radius: .125rem;
  padding: 75px 100px;
  box-shadow: rgba(0, 0, 0, .12) 0 5px 8px;
}


.top-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 320px;
  height: 60px;
}

.page-header { /* Container just in case we want more than just the search input to come along */
  position: absolute;
  top: 52px;
  input {
    transition: width 0.2s;
    -webkit-appearance: none; /* Autoprefixer doesn't catch this */
  }
}

.top {
  height: 54px; /* Space in here for search */
  padding-top: 0px;
  position: relative;
  z-index: 1030;
}

.top-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 320px;
  height: 60px;
  .fix-search & {
    background: #eee;
  }
}

.page-header { /* Container just in case we want more than just the search input to come along */
  position: absolute;
  top: 52px;
  input {
    transition: width 0.2s;
    -webkit-appearance: none; /* Autoprefixer doesn't catch this */
  }
  .fix-page-header & {
    position: fixed;
    top: 0px;
    input {

    }
  }
}

.animated-icon:hover {
  transform: translateZ(0);
  display: inline-block;
  vertical-align: middle;
}

.log-events__vertical-line {
  position: absolute;
  top: 10px;
  bottom: 0;
  color: #e3e3e3;
  -webkit-transform: translateX(-21px);
  transform: translateX(-21px);
  height: 100%;
  z-index: 0;
}

.state-point {
  position: absolute;
  transform: translateX(-18px);
  width: 8px;
  height: 8px;
  background-color: rgba(0, 0, 0, .125);
  border-radius: 50%;
}


// animation for hot plan
.upDownAnimation {
  animation-name : upDown;
  animation-iteration-count : infinite;
  animation-direction : alternate;
  animation-duration : 2.5s, 1.5s;
  animation-delay: 0s,.3s;
  animation-timing-function: ease-out,ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes upDown {
  from {transform: translateY(-2px);}
  to {transform: translateY(8px);}
}

.white-shadow-box {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 0 8px #00000026;
  margin: 10px;
}

.intercom-lightweight-app {
  display: none;
}

span[id^=Tooltip] {
  svg[class^=svg-inline--fa] {
    height: 13px !important;
  }  
}