.react-pdf__Page__canvas {
  width: 100% !important;
  max-width: 1000px;
  height: unset !important;
  margin-left: auto;
  margin-right: auto;
}

.view-file button.btn.btn-secondary {
  padding: 3px;
  font-size: 0.8rem;
}

.modal-dialog.modal-xs {
  max-width: 250px !important;
}