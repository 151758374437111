button {
  outline: none;
}

button.disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}

.icon-and-text.btn svg {
  margin-right: 5px;
}

.pane.head {
  padding: 2px 0px;
}

.pane.head .btn {
  padding: 0px 5px;
}

.pane.head .btn-primary svg {
  color: #ffffff
}

.pane.head .btn-secondary svg {
  color: $secondary;
}

.pane.head .btn-secondary:hover svg {
  color: $secondary-hover;
}

.btn.btn-default {
  //border-color: #6b7074;
  //border-style: solid;
  color: #96969A;
  border: 1px solid #e5e5e5;
}

.btn.btn-default:hover {
  //border-color: #000;
  color: #000000;
  background-color: #f0f0f0;
  border: 1px solid #e5e5e5;
}


.dynamic-list button.btn-icon {
  margin-top: 10px;
}

@media (min-width: 400px) {
  .action-bar button,
  .action-bar a {
    float: right;
    margin-left: 5px;
  }
}

.btn {
  border-radius: 4px;
  font-size: 0.8rem;
  padding: 0.175rem 0.75rem;
  height: 34px;
  letter-spacing: 0.7px;
  font-weight: 800;
}

.btn-primary {
  color: #ffffff;
  border: 1px solid $primary;
}

.btn-primary:hover,
.btn-primary:active {
  color: #ffffff !important;
  background-color: $primary-takeoff !important;
  border: 1px solid $primary;
}

.btn-default,
.btn-sm {
  border: none;
  font-family: "RobotoLight", -apple-system;
  padding: 0.2rem 0.6rem;
}

.btn-default:hover {
  border: none;
  font-family: "RobotoLight", -apple-system;
}


.btn-default {
  background-color: #ffffff;
  color: #000000;
}

.btn-secondary {
  color: $secondary;
  border-color: $secondary;
  background-color: #ffffff;
}

.btn-secondary:focus {
  box-shadow: none !important;
}

.btn-secondary:hover {
  background-color: $secondary;
  color: #ffffff;
}

/* Darstellung für gelbe primary Buttons */
.btn-group button.btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #ffffff;
}

.show > .btn-secondary.dropdown-toggle {
  background-color: $default;
}

// settings

.list-group a {
  text-decoration: none;
}

.list-group a button {
  cursor: pointer;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #FFFFFF;
}

button.dropdown-item:hover {
  background-color: #eeeeee;
  cursor: pointer;
  border-radius: 0px;
}

// own creation
.btn-icon {
  background-color: transparent;
  color: #b5b6b7;
  height: unset;
  padding: 0px;
}

.btn-icon:hover {
  color: #000000;
}

.btn:focus,
.btn.focus{
  box-shadow: none !important;
}

.primary-link {
  color: $primary;
 // background-color: rgba(0, 0, 0, .03);
  border-radius: 4px;
}

.btn-primary:focus {
  color: #ffffff;
}

.primary-link:hover {
  color: $primary-takeoff !important;
  background-color: rgba(0, 0, 0, .03);
  text-decoration: none;
}

.secondary-link {
  color: $secondary;
  // background-color: rgba(0, 0, 0, .03);
  border-radius: 4px;
}

.secondary-link:hover {
  color: $secondary-takeoff !important;
  background-color: rgba(0, 0, 0, .03);
  text-decoration: none;
}

.btn-link {
  padding: 0px;
  font-weight: normal;
  letter-spacing: unset;
  transition: unset;
}

.btn-link:hover {
  color: $primary-takeoff;
}


.primary-link.btn-link,
.primary-link.btn-icon {
    padding: 8px;
}
