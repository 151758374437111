
.ua-radiobox-radioboxLabel {
  font-size: 16px;
  color: #9499a7;
}

.radiobox-radioboxInput {
  position: absolute;
  opacity: 0;
  left: -99999px;
}

.radiobox-radioboxLabel {
  display: inline-block;
  position: relative;
  padding-left: 28px;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  /* user-select: none; */
}

.radiobox-radiobox {
  display: inline-block;
  cursor: pointer;
  margin-bottom: 0px;

}

.radiobox-radioboxLabel::before {
  content: '';
  box-sizing: border-box;
  border: 2px solid #e6e7f0;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 3px;
  left: 0;
  transition: border-color 0.2s ease-in;
  border-radius: 50%;
}

.radiobox-radioboxInput:hover+.radiobox-radioboxLabel.disabled::before {
  border-color: #e6e7f0;
  cursor: not-allowed;
}
.radiobox-radioboxInput:checked+.radiobox-radioboxLabel.disabled::before {
  border-color: #e6e7f0;
  background-color: #7d7d7d;
}

.radiobox-radioboxInput:hover+.radiobox-radioboxLabel::before, .radiobox-radioboxInput:focus+.radiobox-radioboxLabel::before {
  border-color: $primary;
  outline: none;
  border-radius: 50%;
}

.radiobox-radioboxLabel::before {
  content: '';
  box-sizing: border-box;
  border: 2px solid #e6e7f0;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 3px;
  left: 0;
  transition: border-color 0.2s ease-in;
}

.radiobox-radioboxLabel::before {
  content: '';
  box-sizing: border-box;
  border: 2px solid #e6e7f0;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 3px;
  left: 0;
  transition: border-color 0.2s ease-in;
}

.radiobox-radioboxInput:checked+.radiobox-radioboxLabel::before {
  background-color: $primary;
  border: 2px solid $primary;
  border-radius: 50%;
}