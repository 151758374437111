.singleCharacterDropdown .btn-group {
  float: right;
  margin-top : 3px;
}
.singleCharacterDropdown .btn-group button {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.dropdown-menu {
  border-radius: 4px;
  box-shadow: 0 5px 20px 0 rgba(21,27,38,.08);
  border: 1px solid #ddd;
  color: #151b26;
  min-width: 12rem;
  padding: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  transform: none !important;
}

.navbar .dropdown-menu {
  top: 47px !important;
}

  .dropdown-menu label {
  font-size: 0.9rem;
  margin-top: 5px;
  margin-bottom: 5px;
}

.navbar .dropdown-menu a {
  color: #000000;
  font-size: .9rem;
}
.navbar .dropdown-menu a:hover {
  -webkit-border-radius: .125rem;
  border-radius: .125rem;
  -webkit-transition: all .1s linear;
  -o-transition: all .1s linear;
  transition: all .1s linear;
  -webkit-border-radius: .125rem;
  border-radius: .125rem;
}

// suchleiste in navbar

/*
.navbar .dropdown .search-bar {
  display: none;
}*/

.navbar .dropdown .search-bar {
  //display: block;
  margin-top: -45px;
  margin-left: -6px;
}

.navbar .dropdown .search-bar input {
  margin-top: 15px;
  padding-left: 30px;
}


.navbar .dropdown.show .search-bar .form-control {
  padding-bottom: 0px;
  width: 100%;
  margin-top: 15px;
  -webkit-transition: all .1s linear;

  -o-transition: all .1s linear;

  transition: all .1s linear;
}


.dropdown {
  display: inline-block;
}

.dropdown>a.nav-link:hover {
  color: #000000 !important;
}

.dropdown>button {
  color: #000;
  border: none;
}

.dropdown>button:hover {
  //background-color: rgba(0, 0, 0, .03);
  color: #000;
  border: none;
}

.navbar button.dropdown-item {
  padding: 0px
}

.dropdown-item svg {
  margin-right: 5px;
}

.devider {
  border-bottom: 1px solid #e0e6e8;
  margin: 10px 0;
}

.dropdown-toggle.btn {
  padding: 0.175rem 0.75rem;
}

.dropdown-toggle::after {
  margin-left: 0px;
}