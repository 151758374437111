.loading {
  background-color: #ffffff;
  background-image: url("./../../loading-animation.gif");
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
}

input.loading {
  background-position: right 10px center;
}

span.checkbox-checkboxLabel.loading {
  background-size: 12px 12px;
  background-position: left 2px top 5px;
}

.checkbox-checkboxInput.loading:checked + .checkbox-checkboxLabel.loading::before {
  background: none;
}