.open-placeholder-menu:before {
  transform: translateY(-50%) rotate(180deg);
  content: '\25B8';
  color: #fff;
  font-size: 17px;
  margin-left: -15px;
  position: absolute;
  text-shadow: 1px 0px 0px rgba(0, 0, 0, .125);
  margin-top: 9px;
}

.open-placeholder-menu:hover::before {
  color: #f7f7f7;
}

.show-placeholder-menu:before {
  transform: translateY(-50%) rotate(180deg);
  content: '\25B8';
  color: #fff;
  font-size: 17px;
  margin-left: -6px;
  position: absolute;
  text-shadow: 1px 0px 0px rgba(0, 0, 0, .125);
  margin-top: 18px;
}

.placeholder .btn.btn-default {
  font-size: 0.75rem;
  color: #000000;
  background-color: #f7f7f7;
  margin: 0px 5px 5px 0px;
  height: 100%;
}

input.placeholder,
input.placeholder-replacement,
input.placeholder-missing {
  background-color: #eeeeee !important;
  border-radius: 4px;
  padding: 3px;
  border: 0px;
  margin-left: 1px;
  margin-right: 1px;
}
input.placeholder-replacement:hover {
  font-style: italic;
}

/*placeholder:hover span {
  display: none;
}

placeholder:hover::after {
  content: attr(data-placeholder-i18n);
}*/

input.placeholder-missing {
  color: red;
}

.placeholder span {
  font-size: 0.75rem;
}