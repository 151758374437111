
.table .btn-group {
  margin-top: 0px;
  margin-left: 5px;
}

.table .btn-group .btn-sm {
  padding: 0.1rem 0.5rem;
}

.table .btn {
  padding: 0.0rem 0.9rem;
}

.table td,
.table th {
  border: none;
}

.table thead tr th {
  font-family: "RobotoRegular", -apple-system;
  font-weight: bold;
}

.table thead th {
  border: none;
}
