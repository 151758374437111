
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #ff0503;
  border-radius: 0.25rem;}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #ff0503;
  font-weight: 700;}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: rgba(255, 5, 3, 0.06);
  border-radius: 0.25rem;
}
.DayPicker-Day--today {
  color: #000000;
  font-weight: 700;
}

/*.DayPicker-Day--today {
  color: #000000;
  font-weight: 700;
}*/